import { Button, Col } from "react-bootstrap"

export const UpcomingEvent = ({event}) => {
  return (
    <div className="upcoming-event-container">
      <div className="bold medium-text">
        {event.name}
      </div>
      <div className="small-text">
        {event.customer}
      </div>
      <div className="small-text margin-top-1">
        {event.date}
      </div>
      <div className="small-text">
        {event.county}
      </div>
      <div className="small-text margin-top-1">
        Jucatori: {event.confirmedPlayersCount}/{event.maxPlayers}
      </div>
      <div className="small-text">
        Lista de asteptare: {event.waitlistedPlayersCount}
      </div>
      <Button className="small-text pull-right pull-down details-button margin-top-1">Detalii</Button>
    </div>
  )
}