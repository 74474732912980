import {
  FETCH_ROUNDS_STARTED,
  FETCH_ROUNDS_SUCCEEDED,
  FETCH_ROUNDS_FAILED,
  ADD_ROUND_FAILED,
  ADD_ROUND_SUCCEEDED,
  ADD_ROUND_STARTED,
  DELETE_ROUND_FAILED,
  DELETE_ROUND_SUCCEEDED,
  DELETE_ROUND_STARTED,
  END_TOURNAMENT,
} from '../../actions/event_page/play_tab/rounds'

import {
  FETCH_GROUPS_STARTED,
  FETCH_GROUPS_SUCCEEDED,
  FETCH_GROUPS_FAILED,
  FETCH_GROUP_DATA_STARTED,
  FETCH_GROUP_DATA_SUCCEEDED,
  FETCH_GROUP_DATA_FAILED,
  ENTER_SCORE_SUCCEEDED,
  ENTER_SCORE_STARTED,
  QUALIFY_PLAYER_SUCCEEDED,
  DISQUALIFY_PLAYER_SUCCEEDED,
} from '../../actions/event_page/play_tab/groups'

import {
  FETCH_EVENT_DATA_STARTED,
  FETCH_EVENT_DATA_SUCCEEDED,
  FETCH_EVENT_DATA_FAILED,
  RESET_ERROR_STATE,
  FETCH_EVENT_TYPES_STARTED,
  FETCH_EVENT_TYPES_SUCCEEDED,
  FETCH_EVENT_TYPES_FAILED,
} from '../../actions/event_page/event_info'

import { 
  ADD_PLAYER_STARTED,
  ADD_PLAYER_SUCCEEDED,
  ADD_PLAYER_FAILED,
  MOVE_PLAYER_SUCCEEDED,
  MOVE_PLAYER_FAILED,
  MOVE_PLAYER_STARTED,
  DELETE_PLAYER_SUCCEEDED,
  DELETE_PLAYER_FAILED,
  DELETE_PLAYER_STARTED,
  FETCH_PLAYERS_FAILED,
  FETCH_PLAYERS_SUCCEEDED,
  FETCH_PLAYERS_STARTED,
 } from '../../actions/event_page/registration'

import {
  FETCH_EVENT_PLAYER_RESULTS_FAILED,
  FETCH_EVENT_PLAYER_RESULTS_STARTED,
  FETCH_EVENT_PLAYER_RESULTS_SUCCEEDED,
  EDIT_EVENT_RESULT_FAILED,
  EDIT_EVENT_RESULT_STARTED,
  EDIT_EVENT_RESULT_SUCCEEDED,
} from '../../actions/event_page/event_results'

const initialState = {
  status: 'uninitialized',
  error: null,
  event: {},
  registration: {
    status: 'uninitialized',
    error: null,
    managers: [],
    confirmedPlayers: [],
    waitlistedPlayers: [],
    rejectedPlayers: [],
  },
  play: {
    rounds: [],
  },
  results: {
    eventPlayerResults: [],
  },
}

export default function eventPageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENT_DATA_STARTED: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_EVENT_DATA_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        event: action.event,
      }
    }
    case FETCH_EVENT_DATA_FAILED: {
      return {
        ...state,
        status: 'failed',
        error: action.error,
      }
    }
    case FETCH_EVENT_TYPES_STARTED: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_EVENT_TYPES_FAILED: {
      return {
        ...state,
        status: 'failed',
        error: action.error,
      }
    }
    case FETCH_EVENT_TYPES_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        eventTypes: action.eventTypes,
      }
    }
    case FETCH_ROUNDS_STARTED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'loading',
        }
      }
    }
    case FETCH_ROUNDS_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
          rounds: action.rounds,
        }
      }
    }
    case FETCH_ROUNDS_FAILED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case ADD_ROUND_STARTED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'loading',
        }
      }
    }
    case ADD_ROUND_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
          rounds: [
            ...state.play.rounds,
            action.round
          ]
        },
        event: {
          ...state.event,
          eventStageId: action.eventStageId,
        }
      }
    }
    case END_TOURNAMENT: {
      return {
        ...state,
        event: {
          ...state.event,
          eventStageId: 5,
          winner: action.winner,
        },
        play: {
          ...state.play,
          status: 'success',
        }
      }
    }
    case ADD_ROUND_FAILED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case FETCH_GROUPS_STARTED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'loading',
        }
      }
    }
    case FETCH_GROUPS_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
          rounds: state.play.rounds.map(round => round.id === action.roundId ? { ...round, groups: action.groups } : round)
        }
      }
    }
    case FETCH_GROUPS_FAILED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case FETCH_GROUP_DATA_STARTED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'loading',
        }
      }
    }
    case FETCH_GROUP_DATA_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
          rounds: state.play.rounds.map(round => 
            round.id === action.group.roundId 
              ? { 
                  ...round, 
                  groups: round.groups.map(group => 
                    group.id === action.group.id 
                      ? action.group 
                      : group
                  ) 
                } 
              : round
          )}
      }
    }
    case FETCH_GROUP_DATA_FAILED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case ADD_PLAYER_STARTED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'loading'
        }
      }
    }
    case ADD_PLAYER_SUCCEEDED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'success',
          confirmedPlayers: action.response.confirmedPlayers,
          waitlistedPlayers: action.response.waitlistedPlayers,
          rejectedPlayers: action.response.rejectedPlayers,
        }
      }
    }
    case ADD_PLAYER_FAILED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case MOVE_PLAYER_STARTED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'loading'
        }
      }
    }
    case MOVE_PLAYER_SUCCEEDED : {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'success',
          confirmedPlayers: action.data.confirmedPlayers,
          waitlistedPlayers: action.data.waitlistedPlayers,
          rejectedPlayers: action.data.rejectedPlayers,
        }
      }
    }
    case MOVE_PLAYER_FAILED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'failed',
          error: action.error
        }
      }
    }
    case DELETE_PLAYER_STARTED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'loading'
        }
      }
    }
    case DELETE_PLAYER_FAILED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case DELETE_PLAYER_SUCCEEDED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'success',
          confirmedPlayers: action.data.confirmedPlayers,
          waitlistedPlayers: action.data.waitlistedPlayers,
          rejectedPlayers: action.data.rejectedPlayers,
        }
      }
    }
    case QUALIFY_PLAYER_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
        }
      }
    }
    case DISQUALIFY_PLAYER_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
        }
      }
    }
    case RESET_ERROR_STATE: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          error: null,
        }
      }
    }
    case ENTER_SCORE_SUCCEEDED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'success',
        }
      }
    }
    case DELETE_ROUND_STARTED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'loading',
        }
      }
    }
    case DELETE_ROUND_SUCCEEDED: {
      return {
        ...state,
        play: {
          status: 'success',
        }
      }
    }
    case DELETE_ROUND_FAILED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case FETCH_EVENT_PLAYER_RESULTS_FAILED: {
      return {
        ...state,
        results: {
          ...state.results,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case FETCH_EVENT_PLAYER_RESULTS_STARTED: {
      return {
        ...state,
        results: {
          ...state.results,
          status: 'loading',
        }
      }
    }
    case FETCH_EVENT_PLAYER_RESULTS_SUCCEEDED: {
      return {
        ...state,
        results: {
          ...state.results,
          status: 'success',
          eventPlayerResults: action.results,
        }
      }
    }
    case EDIT_EVENT_RESULT_STARTED: {
      return {
        ...state,
        results: {
          ...state.results,
          status: 'loading',
        }
      }
    }
    case EDIT_EVENT_RESULT_SUCCEEDED: {
      return {
        ...state,
        results: {
          ...state.results,
          status: 'success',
          eventPlayerResults: state.results.eventPlayerResults.map(result =>
            result.id === action.eventPlayerResult.id ? action.eventPlayerResult : result
          ),
        }
      }
    }
    case EDIT_EVENT_RESULT_FAILED: {
      return {
        ...state,
        results: {
          ...state.results,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case ENTER_SCORE_STARTED: {
      return {
        ...state,
        play: {
          ...state.play,
          status: 'loading',
        }
      }
    }
    case FETCH_PLAYERS_FAILED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case FETCH_PLAYERS_STARTED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'loading',
        }
      }
    }
    case FETCH_PLAYERS_SUCCEEDED: {
      return {
        ...state,
        registration: {
          ...state.registration,
          status: 'success',
          confirmedPlayers: action.playersData.confirmedPlayers,
          waitlistedPlayers: action.playersData.waitlistedPlayers,
          rejectedPlayers: action.playersData.rejectedPlayers,
          averagePoints: action.playersData.averagePoints,
        }
      }
    }
    default:
      return state
  }
}