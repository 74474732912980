import React from 'react'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { editAverageCalculationRule } from '../../../actions/customer_dashboard/season_points'

export const AverageCalculationRulesForm = ({rules}) => {
  const [ignoredEvents, setIgnoredEvents] = useState(rules.excludedEventsCount)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    const newRules = {
      id: rules.id,
      excludedEventsCount: ignoredEvents
    }
    dispatch(editAverageCalculationRule(rules.eventTypeId, newRules))
  }

  return (
    <>
      <Form className='border-bottom-1 padding-bottom-1'>
        <Form.Label className="medium-text">Numarul de evenimente de aceasta categorie ignorate</Form.Label>
        <Form.Control 
          type="number" 
          defaultValue={ignoredEvents} 
          onChange={(e) => setIgnoredEvents(parseInt(e.target.value, 10))} 
          className="margin-top-1 margin-bottom-1" 
          size="md"
        />
        <Button className="primary-gradient-gradient pull-right margin-top-1" onClick={handleSubmit}>Salveaza</Button>
      </Form>
    </>
  )
}