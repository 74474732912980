import logo from '../../../images/logo_white.png'
import { Col, Row, Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { checkAuth, logout } from '../../../actions/users'
import { Footer } from '../footer'
import { LoggedOutDropdown } from './logged_out_dropdown'
import { LoggedInDropdown } from './logged_in_dropdown'
import { HamburgerDropdown } from './hamburger_dropdown'
import SimpleBar from 'simplebar-react'

export const Header = ({children}) => {

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  const dispatch = useDispatch()

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(logout())
  }

  return (
    <>
      <SimpleBar style={{ height: '100vh' }}>
        <Container fluid >
          <Row className="header-container">
            <Col md={4} xs={4} className='center-content-v'>
              <div className='left-content margin-left-1'>
                <HamburgerDropdown />
              </div>
            </Col>
            <Col md={4} xs={4} className='center-content'>
              <a className='header-link' href='/'>
                <img alt='top-spin-logo' src={logo} className='logo'></img>
              </a>
            </Col>
            <Col md={{span: 1, offset: 3}} xs={4} className='center-content-v'>
            <div className='right-content margin-right-1'>
              {
                isAuthenticated ? 
                <LoggedInDropdown customerId={user.customerId} handleLogout={handleLogout} />
                :
                <LoggedOutDropdown />
              }

            </div>
            </Col>
          </Row>
        </Container>
        {children}
        <Footer/>
      </SimpleBar>
    </>
  )
}
