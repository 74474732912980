import { Button, Row, Col } from "react-bootstrap"

export const OrganisationWidget = () => {
  return (
    <div className="home-section-container">
      <div className="xxl-text accent-color bold margin-bottom-1 border-bottom-1">
        Ce poti face cu o organizatie?
      </div>
      <Row className="large-text bold primary-color margin-top-d5">
        <Col md={{span: 3, offset: 1}} >Adauga administratori</Col>
        <Col className="xxl-text" md={{span: 3, offset: 1}} >Creeaza turnee</Col>
        <Col md={{span: 2, offset: 2}} >Publica turnee</Col>
      </Row>
      <Row className="large-text bold primary-color margin-top-d5">
        <Col className="xxl-text" md={{span: 2, offset: 2}} >Inscrieri</Col>
        <Col md={{span: 3, offset: 3}} >Liste de asteptare</Col>
      </Row>
      <Row className="large-text bold primary-color margin-top-d5">
        <Col md={{span: 2, offset: 1}} >Grupe</Col>
        <Col md={{span: 3, offset: 2}} >Tablouri eliminatorii</Col>
        <Col className="xxl-text" md={{span: 2, offset: 2}} >Scoruri</Col>
      </Row>
      <Row className="large-text bold primary-color margin-top-d5">
        <Col className="xxl-text" md={{span: 2, offset: 3}} >Clasamente</Col>
        <Col md={{span: 3, offset: 2}} >Puncte de sezon</Col>
        <Col md={{span: 2, offset: 2}} >Statistici</Col>
        <Col className="xxl-text" md={{span: 3, offset: 2}} >Istoric turnee</Col>

      </Row>
      {/* <Row className="margin-top-1">
        <Col md={{span: 6, offset: 4}} className="margin-top-1">
          <Button className="accent xxl-text no-border center-content-h">Creeaza o organizatie</Button>
        </Col>
      </Row> */}

      
    </div>
  )
}