import { Accordion, Col} from "react-bootstrap"

export const PlayersInfo = ({maxPlayers, confirmedPlayers, waitlistedPlayers, rejectedPlayers}) => {
  return (
    <Col xs={12} md={4} className="detailed-event-column">
      <div className="large-text bold primary-gradient-color border-bottom">Jucatori {confirmedPlayers.length}/{maxPlayers}</div>
      <div className="detailed-players-container margin-bottom-1 margin-top-1">
        {confirmedPlayers.map(player => 
          <div className="medium-text margin-bottom">
            {player.position}. {player.name} - {player.city}
          </div>
        )}
      </div>
      <Accordion>
        <Accordion.Item eventKey='waitlist'>
          <Accordion.Header>Lista de asteptare</Accordion.Header>
          <Accordion.Body>
            <div className="detailed-players-container">
              {waitlistedPlayers.map(player => 
                <div className="medium-text margin-bottom">
                  {player.name} - {player.city}
                </div>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='rejected'>
        <Accordion.Header>Jucatori Respinsi</Accordion.Header>
        <Accordion.Body>
          <div className="detailed-players-container">
            {rejectedPlayers.map(player => 
              <div className="medium-text margin-bottom">
                {player.name} - {player.city}
              </div>
            )}
          </div>
        </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  )
}