import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchUpcomingEvents } from '../../actions/home_page';
import { selectUpcomingEvents, selectUpcomingEventsStatus } from '../../selectors/home_page';
import { LoadingPage } from '../shared/loading_page';
import { UpcomingEvent } from './upcoming_event';
import { Button, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
export const UpcomingEvents = () => {

  const dispatch = useDispatch();

  const events = useSelector(selectUpcomingEvents);
  const status = useSelector(selectUpcomingEventsStatus);

  useEffect(() => {
    dispatch(fetchUpcomingEvents());
  }, [dispatch]);

  return (
    <div className="home-section-container">
      <div className="xxl-text accent-color bold">
        Evenimente Urmatoare
        {
          status === 'loading'
          ?
          <LoadingPage/>
          :
          <div>
            <SimpleBar direction='rtl'>
              <div className='margin-top-1 margin-bottom-1 upcoming-events-container'>
                {
                  events && events.length > 0
                  ?
                  events.map((event) => (
                    <UpcomingEvent key={event.id} event={event}/>
                  ))
                  :
                  <div className="text-center margin-top-1">Nu exista evenimente</div>
                }
              </div>
            </SimpleBar>
          </div>
        }
        <Button href='/events' className="medium-text no-border pull-right accent margin-top-1">Vezi Toate Evenimentele</Button>
      </div>
    </div>
  )
}