import './App.css';
import './style/event.css';
import './style/header.css';
import './style/login.css';
import './style/register.css';
import './style/customer.css';
import './style/group.css';
import './style/playground.css';
import './style/home.css';
import 'simplebar-react/dist/simplebar.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react';
import React from "react";
 
import { Route, Routes } from "react-router-dom";
import { EventsRoot } from './components/events/events_root';
import { EventFormContainer } from './components/events/form/event_form_container';
import { Login } from './components/users/login';
import { Register } from './components/users/register'
import { Header } from './components/wrapper/header/root';
import { CustomerForm } from './components/customer/new';
import { CustomerRoot } from './components/customer/customer_root';
import { EventDashboard } from './components/events/event_dashboard/root';
import { EventPage } from './components/events/event_page/root';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Roadmap } from './components/roadmap';
import { HomePage } from './components/home/root';
import { HomeHeader } from './components/wrapper/header/home_header';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route exact path="/" element={<HomeHeader><HomePage /></HomeHeader>} />
            <Route exact path="/events" element={<Header><EventsRoot /></Header>} />
            <Route exact path="/events/new" element={<Header><EventFormContainer /></Header>} />
            <Route exact path="/customers/:customerId/events/:eventId" element={<Header><EventDashboard/></Header>} />
            <Route exact path="/events/:eventId" element={<Header><EventPage/></Header>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/register" element={<Register/>} />
            <Route exact path="/customers/new" element={<Header><CustomerForm/></Header>} />
            <Route exact path="/customers/:customerId" element={<Header><CustomerRoot/></Header>} />
            <Route exact path="/roadmap" element={<Header><Roadmap/></Header>} />
          </Routes>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
