import React from 'react';
import IconButton from '../../../shared/icon_button';
import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { editEventResult } from '../../../../actions/event_page/event_results';
import { useDispatch } from 'react-redux';

export const PlayerResult = ({ index, result, hasDifferentPlace, resultsLocked }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [pointsEarned, setPointsEarned] = useState(result.pointsEarned);
  const dispatch = useDispatch();

  const handleEdit = () => {
    setIsEditing(true);
  }

  const handleSave = () => {
    dispatch(editEventResult(result.eventId, result.id, pointsEarned));
    setIsEditing(false);
  }

  const handleChange = (e) => {
    setPointsEarned(e.target.value);
  }

  const PointCount = () => {
    if (isEditing) {
      return (
        <Form.Control
          type="number"
          value={pointsEarned}
          onChange={handleChange}
          size='md'
          className='text-center'
        />
      );
    }
    else
      return result.pointsEarned
  }

  const ActionsColumn = () => {
    if (isEditing)
      return (
      <div className='d-flex justify-content-center'>
        <Row>
          <Col>
            <IconButton height={18} width={18} icon={'check'} onClick={handleSave} />
          </Col>
          <Col>
            <IconButton height={18} width={18} icon={'close'} onClick={() => setIsEditing(false)} />
          </Col>
        </Row>
      </div>
      );
    else
      return (
        <div className='d-flex justify-content-center'>
          <IconButton height={18} width={18} icon={'edit'} onClick={handleEdit} />
        </div>   
      );
  }

  return (
    <tr key={result.id} className={`center-content-text-table ${(hasDifferentPlace ? 'border-bottom-dark-1' : '')}`}>
      <td>{index + 1}</td>
      <td>{result.player.name}</td>
      {!result.confirmed && <td>{result.average}</td>}
      <td>
        <PointCount/>
        {!result.confirmed && <i className="fa fa-question-circle margin-left-1" title={result.explanation}></i> }
      </td>
      { !resultsLocked && <td className='align-middle'><ActionsColumn/></td>}
    </tr>
  );
}