import { Button, ListGroup, Accordion, Row, Col, Modal, Form} from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selfRegisterPlayer } from '../../actions/events_page';
import { selectAuthenticated, selectUser} from '../../selectors/users';
import ReactQuill from 'react-quill';

const Event = ({key, event}) => {

  const [show, setShow] = useState(false);
  const [contactHidden, setContactHidden] = useState(true);

  const isAuthenticated = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)


  const currentUserRegistered = user ? event.players.some(player => player.phone === user.phone) : false;

  const [name, setName] = useState(isAuthenticated ? user.name : '');
  const [phone, setPhone] = useState(isAuthenticated ? user.phone : '');
  const [city, setCity] = useState(isAuthenticated ? user.city : '');

  const dispatch = useDispatch()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const handleCityChange = (event) => {
    setCity(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(selfRegisterPlayer({name, phone, city, userId: user?.id, eventId: event.id}));
    handleClose();
  }

  const SignUpButton = () => {
    const getButtonText = () => {
      if (event.eventStageId === 1) {
        if (currentUserRegistered) return 'Inscris';
        if (event.registration_closed) return 'Inscrieri Inchise';
        return 'Inscriere Online';
      }
      if (event.eventStageId >= 2 && event.eventStageId < 5) return 'In Desfasurare';
      if (event.eventStageId === 5) return 'Incheiat';
      return '';
    };

    const isDisabled = () => {
      if (event.eventStageId === 1) {
        return currentUserRegistered || event.registration_closed;
      }
      return event.eventStageId >= 2 && event.eventStageId <= 5;
    };

    return (
      <Button 
        disabled={isDisabled()} 
        className='event-sign-up-button medium-text' 
        onClick={handleShow}
      >
        {getButtonText()}
      </Button>
    );
  };

  return (
    <div className='event-container'>
      <Row>
        <Col md={11}>
          <Row className='upper-event'>
            <Col md={3} className='name-organizer primary-gradient rounded-padded'>
              <div className='large-text extra-bold'>{event.name}</div>
              <div className='small-text bold'>{event.customer.name}</div>
            </Col>
            <Col md={{span: 2, offset: 1}} className='location-time-container'>
              <span >{event.date.slice(0,10)}</span>
              <span>{event.date.slice(11,16)}</span>
              <span >{event.location} {event.county}</span>
            </Col>
            <Col md={3} className='center-content-h'>
              <SignUpButton></SignUpButton>
            </Col>
            <Col md={3} className='center-content-h'>
              <div className='managers-container'>
                <div className='manager'>
                  {
                    contactHidden
                    ?
                    <Button variant="outline-dark" className='medium-text contact-button' onClick={() => {setContactHidden(false)}}>Contact</Button> :
                    <ListGroup>
                      <ListGroup.Item action onClick={() => {setContactHidden(true)}} className='manager-info'>
                        {event.customer.managers[0].firstName} {event.customer.managers[0].lastName}
                      </ListGroup.Item>
                      <ListGroup.Item action onClick={() => {setContactHidden(true)}} className='manager-info'>
                        {event.customer.managers[0].phone}
                      </ListGroup.Item>
                    </ListGroup>
                  }
                </div>
              </div>
            </Col>
          </Row>
          <Row className='lower-event'>
            <Col md={6}>
              <Accordion className='border-top-1' flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Descriere
                  </Accordion.Header>
                  <Accordion.Body>
                    <ReactQuill
                      value={event.description}
                      readOnly
                      theme='bubble'>
                      
                    </ReactQuill>                    
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={3}>
              <Accordion className='border-top-1' flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Jucatori: {event.confirmedPlayers.length} / {event.maxPlayers}
                  </Accordion.Header>
                  <Accordion.Body>
                    {event.confirmedPlayers.map((player, index) => (
                      <ListGroup.Item key={index}>{player.name}</ListGroup.Item>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={3}>
              <Accordion className='border-top-1' flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header >
                    Lista de asteptare: {event.waitlistedPlayers.length}
                  </Accordion.Header>
                  <Accordion.Body>
                    {event.waitlistedPlayers.map((player, index) => (
                      <ListGroup.Item key={index}>{player.name}</ListGroup.Item>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>

        </Col>
        <Col md={1}>
          <Button href={`/events/${event.id}`} className='event-col-button center-content medium-text'>
            Detalii
          </Button>
        </Col>
      </Row>
      
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Inscriere {event.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className='margin-bottom-1'>Nume</Form.Label>
            <Form.Control defaultValue={name} onChange={handleNameChange}></Form.Control>
            <Form.Label className='margin-bottom-1 margin-top-1'>Numar de telefon</Form.Label>
            <Form.Control defaultValue={phone} onChange={handlePhoneChange}></Form.Control>
            <Form.Label className='margin-bottom-1 margin-top-1'>Oras</Form.Label>
            <Form.Control defaultValue={city} onChange={handleCityChange}></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary-gradient" onClick={handleSubmit}>
            Inscriere
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Event