import { Accordion, Row, Col, Button, Form } from "react-bootstrap"
import { AverageCalculationRulesForm } from "./average_calculation_rules_form"
import { PointCalculationRulesForm } from "./point_calculation_rules_form"
import { useEffect, useState } from "react"
import { createEventType, fetchEventTypes } from "../../../actions/customer_dashboard/season_points"
import { useDispatch, useSelector } from "react-redux"
import { selectEventTypes} from "../../../selectors/customer_dashboard"

export const CustomerSeasonPointsRoot = () => {

  const [ creatingNewType, setCreatingNewType ] = useState(false)
  const [ newType, setTypeName ] = useState('')
  const eventTypes = useSelector(selectEventTypes)

  const dispatch = useDispatch()

  const handleCreating = () => {
    setCreatingNewType(!creatingNewType)
  }

  const handleSubmit = () => {
    setCreatingNewType(!creatingNewType)
    dispatch(createEventType(newType))
  }

  useEffect(() => {
    dispatch(fetchEventTypes())
  }
  , [dispatch])

  return (
    <>
      <div className="xxl-text margin-bottom-1">
        Categorii de evenimente
      </div>
      {
        eventTypes && eventTypes.length > 0 ? (
          eventTypes.map((type) => {
            return (
              <Accordion className="border-1 rounded-padded bg-white margin-bottom-1" key={type.id}>
                <Accordion.Header className="small-text">{type.name}</Accordion.Header>
                <Accordion.Body>
                  <Col>
                    <Row className="padding-1">
                      <div className="bold margin-bottom-1 large-text">Acordare puncte in turneu</div>
                      <AverageCalculationRulesForm rules={type.averageCalculationRules[0]}/>
                    </Row>
                    <Row className="padding-1">
                      <div className="bold margin-bottom-1 large-text">Calcul total de puncte</div>
                      <PointCalculationRulesForm rules={type.pointCalculationRules[0]} />
                    </Row>
                  </Col>
                </Accordion.Body>
              </Accordion>
            )
          })
        ) : (
          <div className="text-center margin-top-1">Nu exista categorii de evenimente</div>
        )
      }
      {
        creatingNewType
        ?
        <div className="rounded-padded bg-white border-1 margin-top-1">
          <Form className="padding-1">
            <Row>
              <Col md={9}>
                <Form.Control 
                  className="margin-top-1 margin-bottom-1"
                  placeholder='Nume categorie'
                  size="md"
                  onChange={(e) => {setTypeName(e.target.value)}}/>  
              </Col>
              <Col md={3}>
                <Button className="margin-top-1 no-border accent" onClick={handleCreating}>Renunta</Button>
                <Button className="margin-top-1 margin-left-1 primary-gradient" onClick={handleSubmit}>Creeeaza Categorie</Button>
              </Col>
            </Row>
          </Form>
        </div>
        :
        <Button 
          className="primary-gradient margin-top-1"
          onClick={handleCreating}
        >
          Categorie noua
        </Button>
      }
    </>
  )
}